<template>
  <div class="newsedit">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('newsedit.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
          <div class="row">
            <va-input class="flex md2" v-model="newData.showStart" :placeholder="$t('common.date')" type="date" />
            <div class="flex md2">
              <va-select :label="$t('newsedit.newsType')" v-model="newData.newsType" :options="newsTypesList" clear-value="info" />
            </div>
            <va-input class="flex md6"  v-model="newData.newsText" :placeholder="$t('newsedit.newsText')" />
            <div class="flex md2">
              <va-button small color="info" @click="saveNews(newData)"> {{ $t('common.new') }}</va-button>
            </div>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      title="Meldung löschen"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteMessage()"/>
    <div class="row" v-for="(msg,msgK) in newsList" :key="msgK">
      <div class="flex md12">
        <va-card>
          <div class="row">
            <va-input v-model="msg.showStart" class="flex md2" type="date" @input="msg.isDirty=true" />
            <div class="flex md2">
              <va-select v-model="msg.newsType" :options="newsTypesList" clear-value="info" @input="msg.isDirty=true" />
            </div>
            <va-input v-model="msg.newsText" class="flex md6" @input="msg.isDirty=true" />
            <div class="flex md1">
              <va-button small v-if="msg.isDirty" @click="saveNews(msg)"> {{ $t('common.save') }}</va-button>
            </div>
            <div class="flex md1">
              <va-button small @click="askDeleteMessage(msg)" color="danger"> {{ $t('common.delete') }}</va-button>
            </div>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'newsedit',
  data () {
    this.getNews()
    return {
      newsList: {},
      newsTypesList: ['success', 'info', 'warning', 'danger'],
      newData: {
        newsID: -1,
        isActive: 1,
        showStart: '',
        newsType: 'info',
        newsText: '',
      },
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
        newsID: -1,
      },
    }
  },
  methods: {
    getNews () {
      this.$srvconn.sendMessage('getAllNews')
        .then(response => {
          if (response.msgType === 'getAllNews') {
            this.newsList = response.userData.newsList
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveNews (msg) {
      var usrData = {
        newsID: msg.newsID,
        isActive: msg.isActive,
        showStart: msg.showStart,
        newsType: msg.newsType,
        newsText: msg.newsText,
      }
      this.$srvconn.sendMessage('saveNews', usrData)
        .then(response => {
          this.currState.msg = response.userData
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
          } else {
            this.currState.msgType = 'success'
            msg.isDirty = false
            if (msg.newsID === -1) {
              msg.isActive = 1
              msg.showStart = ''
              msg.newsType = 'info'
              msg.newsText = ''
              this.getNews()
            }
          }
          // console.log(response)
        })
        .catch(err => {
          console.log('ERROR: ' + err)
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
    askDeleteMessage (msg) {
      this.toDelete.msg = this.$t('newsedit.delMsg1') + msg.newsText +
                          this.$t('newsedit.delMsg2')
      this.toDelete.newsID = msg.newsID
      this.toDelete.showDeleteModal = true
    },
    deleteMessage () {
      if (this.toDelete.newsID > 0) {
        var usrData = {
          newsID: this.toDelete.newsID,
        }
        this.$srvconn.sendMessage('deleteNews', usrData)
          .then(response => {
            this.currState.msg = response.userData
            if (response.msgType === 'error') {
              this.currState.msgType = 'error'
            } else {
              this.currState.msgType = 'success'
              this.toDelete.newsID = -1
              this.toDelete.msg = ''
              this.getNews()
            }
            // console.log(response)
          })
          .catch(err => {
            console.log('ERROR: ' + err)
            this.currState.msgType = 'error'
            this.currState.msg = err
            this.toDelete.newsID = -1
            this.toDelete.msg = ''
          })
      }
    },
  },
}
</script>
